import Carousel from 'stimulus-carousel'

export default class extends Carousel {

  // values
  static values = {
    slidesPerView: Number,
    autoplay: Boolean,
    paginationEl: String,
    loop: Boolean,
    showPartialSlides: Boolean
  }

  connect() {
    super.connect()

    // The swiper instance.
    this.swiper

    // Default options for every carousels.
    this.defaultOptions
  }

  // You can set default options in this getter.
  get defaultOptions() {
    if (this.showPartialSlidesValue) {
      return {
        cssMode: false,
        slidesPerView: 'auto',
        spaceBetween: 50,
        loop: this.loopValue || false,
        loopedSlides: this.calculateLoopedSlides(),
        autoplay: this.autoplayValue,
        touchAngle: 30,
        threshold: 20,
        speed: 150,
        grabCursor: true,
        initialSlide: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: this.paginationElValue,
          clickable: true,
          type: 'bullets'
        }
      }
    }

    // Return original default options for other carousels
    return {
      cssMode: true,
      slidesPerView: this.slidesPerViewValue || 1,
      loop: this.loopValue || false,
      autoplay: this.autoplayValue,
      touchAngle: 30,
      threshold: 20,
      speed: 150,
      longSwipesMs: 150,
      shortSwipes: true,
      shortSwipesMs: 150,
      grabCursor: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: this.paginationElValue,
        clickable: true,
        type: 'bullets'
      }
    }
  }

  calculateLoopedSlides() {
    const slides = this.element.querySelectorAll('.swiper-slide')
    return Math.ceil(slides.length / 2)
  }
}
