import Carousel from 'stimulus-carousel'

export default class extends Carousel {

  // values
  static values = {
    slidesPerView: Number,
    autoplay: Boolean,
    paginationEl: String,
    loop: Boolean,
    showPartialSlides: Boolean
  }

  connect() {
    // Wait for DOM content to be loaded
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.initializeSwiper());
    } else {
      this.initializeSwiper();
    }
  }

  initializeSwiper() {
    // Check if all images are loaded
    const images = this.element.querySelectorAll('img');
    const allImagesLoaded = Array.from(images).every(img => img.complete);

    if (!allImagesLoaded) {
      // Wait for all images to load
      Promise.all(
        Array.from(images).map(img => {
          if (img.complete) return Promise.resolve();
          return new Promise(resolve => {
            img.onload = resolve;
            img.onerror = resolve;
          });
        })
      ).then(() => {
        super.connect();
      });
    } else {
      super.connect();
    }
  }

  // You can set default options in this getter.
  get defaultOptions() {
    return {
      cssMode: false,
      slidesPerView: 'auto',
      spaceBetween: 50,
      loop: this.loopValue || false,
      autoplay: this.autoplayValue,
      touchAngle: 30,
      threshold: 20,
      speed: 150,
      grabCursor: true,
      initialSlide: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: this.paginationElValue,
        clickable: true,
        type: 'bullets'
      }
    }
  }
}
